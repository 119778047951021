.app-pagination-wrapper {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination-group {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.pagination-group .ui.form {
    min-width: 0px;
    width: 85px;
}

.pagination-group .pagination-form.ui.form .fields {
    margin-bottom: 0;
}

.pagination-group .sortby_options {
    min-width: 170px;
}

.button-load-more {
    margin-left: 12px;
}

@media (max-width: 767px) {
    .ui.form.pagination-form .fields .field {
        margin-bottom: 0 !important;
    }
}
