.app-card-building-units {
    margin-top: 5px;
}

.app-card-building-units span {
    font-weight: 600;
    margin-right: 12px;
}

.view-building-section {
    border-top: 1px solid #f5f5f5;
    padding: 20px 0;
}

.view-building-row {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;
}

.view-building-label {
    width: 205px;
    color: #000000;
}

.view-building-field {
    flex: 1;
}

@media (max-width: 560px) {
    .view-building-row + .view-building-row {
        margin-top: 4px;
    }
    
    .view-building-label,
    .view-building-field {
        width: 100%;
    }
}