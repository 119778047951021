.user-profile-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

.user-profile-col {
    flex: 0 0 auto;
    width: 25%;
    padding-left: 5px;
    padding-right: 5px;
    min-height: 1px;
}

.user-profile-card.app-card {
    padding: 45px 30px;
    margin-bottom: 10px;
}

.user-profile-label {
    font-size: 14px;
    font-weight: 600;
}

.user-profile-info {
    font-size: 14px;
    color: #A9A5A5;
    margin-top: 12px;
}

.pin-date-and-time {
    font-size: 14px;
    color: #A9A5A5;
    margin-top: 12px;
}

.user-profile-info .ui.label {
    cursor: pointer;
}

.user-profile-info .ui.label img {
    width: 12px !important;
    height: 12px !important;
    margin-right: 6px;
}

@media (max-width: 1200px) {
    .user-profile-col {
        width: 50%;
    }

    .user-profile-col:nth-child(n + 3) {
        margin-top: 40px;
    }
}

@media (max-width: 560px) {
    .user-profile-col {
        width: 100%;
    }

    .user-profile-col:nth-child(n + 2) {
        margin-top: 40px;
    }
}