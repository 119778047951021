.app-sidebar {
    position: fixed;
    left: calc(50% - 660px);
    right: 0;
    top: 70px;
    bottom: 0;
    max-width: 250px;
    transform: translate(-50%);
    background-color: #ffffff;
    box-shadow: 2px 2px 2px 0px rgb(0 0 0 / 10%);
    z-index: 980;
}

.app-main-admin .app-content {
	margin-left: 250px;
    padding: 45px 0 45px 30px;
}

.app-nav-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 102px;
    padding: 40px 0 20px;
    overflow-y: auto;
}

.app-sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
}

.app-sidebar-nav li a {
    display: flex;
    font-size: 14px;
    color: #888686;
    padding: 15px 20px;
    position: relative;
}

.app-sidebar-nav li a:hover,
.app-sidebar-nav li a.active {
    background-color: #f5f5f5;
}

.app-sidebar-nav li a::before {
    content: "";
    background-color: #D91B27;
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.app-sidebar-nav li a.active::before {
    display: block;
}

.app-sidebar-nav li a img {
    max-width: 18px;
    margin-right: 12px;
}

.app-sidebar-nav.app-sidebar-bottom-link {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.app-sidebar-nav-item {
    display: flex;
    font-size: 14px;
    color: #888686;
    padding: 15px 20px;
    position: relative;
    cursor: pointer;
}

.app-sidebar-nav-item:hover {
    background-color: #f5f5f5;
}

.app-sidebar-nav-item img {
    max-width: 18px;
    margin-right: 12px;
}

@media (max-width: 1630px) {
    .app-sidebar {
        transform: translate(0);
        left: 0; 
    }

    .app-main-admin .app-content {
        margin-left: 220px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .app-sidebar {
        width: 60px;
        overflow: hidden;
    }

    .app-sidebar-nav li a,
    .app-sidebar-nav-item {
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .app-sidebar-nav li a span,
    .app-sidebar-nav-item span {
        display: none;
    }

    .app-sidebar-nav li a img,
    .app-sidebar-nav-item img {
        margin: 0;
    }

    .app-main-admin .app-content {
        margin-left: 30px;
    }
}

@media (max-width: 991px) {

    .app-main-admin .app-content {
        margin-left: 45px;
    }
    .app-main-admin .app-content {
        padding: 30px 0 30px 15px;
    }

}

@media (max-width: 767px) {
    .app-sidebar {
        max-width: 240px;
        width: 100%;
        position: fixed;
        top: 70px;
        bottom: 0;
        left: 0;
        transition: all 0.35s;
        transform: translateX(-100%);
        box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.025);
        z-index: 980;
        visibility: hidden;
        opacity: 0;
    }

    .app-sidebar.nav-open {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
    }

    .app-nav-holder {
        padding-top: 20px;
    }

    .app-main-admin .app-content {
        margin-left: 0;
        padding-left: 0;
    }


}