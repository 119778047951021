* {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

body {
	background-color: #fafafb;
	color: #4c4c4c;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	vertical-align: middle;
}

/*  CONTAINER 
-------------------------------------------------------------------------- */
.app-wrapper {
	min-height: 100vh;
	padding-bottom: 50px;
	position: relative;
}

.app-container {
	max-width: 1630px;
	margin: 0 auto;
	padding: 0 30px;
  	position: relative;
}

.app-content {
    padding-top: 50px;
    padding-bottom: 50px;
}

.app-page-header {
    border-bottom: 1px solid #D6D9D3;
    padding-bottom: 15px;
    margin-bottom: 30px;
}

.app-page-title {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
}

.app-content-action {
	display: flex;
    justify-content: space-between;
    align-items: center;
	margin-bottom: 20px;
}

.app-content-action-right {
    margin-left: auto;
}

.app-content-action .ui.button {
    padding: 6px 20px;
}

.app-content-action .ui.button img {
    height: 25px;
}

.app-content-action-right .app-csv-link {
    margin: 0 10px;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    padding: 7.5px 16px;
    border: 1px solid #2196f3;
    vertical-align: middle;
    color: #2196f3;
}

.app-content-action-right .app-csv-link:hover {
    background-color: #2196f3;
    color: #ffffff;
}

.app-backto-prev-page {
    margin-bottom: 20px;
}

.app-goback-button {
    font-size: 14px;
    font-weight: 600;
    color: #B7B2B2;
    cursor: pointer;
    display: inline-block;
}

.app-goback-button:hover {
    color: #4c4c4c;
}

.ui.visible.right.overlay.sidebar {
    min-width:420px!important;
    max-width:100%!important;
}

.sidebar .column .visible.menu.transition {
    width: 130px;
}

.ui.dimmer.mobile-nav-dimmer {
    z-index: 960;
}

.ui.inverted.dimmer.mobile-nav-dimmer {
    background-color: rgba(255,255,255,0.65);
}

.sidebar .ui.container.grid.customGrid {
    max-height: 840px;
    width: 100% !important;
    overflow-y: auto;
    height: 630px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }


.nodata-placeholder {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #B7B2B2;
    padding-top: 16px;
}

.ui.sortable.table thead th {
    border-left: none !important;
}

.ui.sortable.table thead th.sorted {
    background: transparent !important;
}

.react-toast-notifications__toast {
    margin-top: 65px;
}

.disabled.field.pin-class {
    opacity: 1 !important;
}

.disabled.field.pin-class label {
    opacity: 1 !important;
}

@media (max-width: 991px) {

    .app-container {
        padding: 0 15px;
    }

}



/*  BUTTONS
-------------------------------------------------------------------------- */
.ui.button {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 13px 20px;
    margin: 0;
}

.ui.button + .ui.button {
    margin-left: 8px;
}

.ui.button img {
    margin-right: 10px;
}


/*  FORMS
-------------------------------------------------------------------------- */
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"],
.ui.form textarea {
    background-color: #ffffff;
    border: 1px solid #e5e6e9;
    box-shadow: none;
	color: #4c4c4c;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.ui.selection.dropdown {
    border: 1px solid #e5e6e9;
}

.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form input[type="email"]:focus,
.ui.form input[type="file"]:focus,
.ui.form input[type="number"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form input[type="tel"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="time"]:focus,
.ui.form input[type="url"]:focus,
.ui.form textarea:focus {
    border-color: #767676;
}

.ui.form textarea{
    line-height: 1.5;
}

.ui.selection.active.dropdown .menu,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu {
    border-color: #767676;
}

.ui.form .fields > .field:not(.wide) {
    flex: 1 0 0%;
}

.ui.form .field > label {
    color: #4c4c4c;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 6px;
}

.ui.form .fields > .field .ui.checkbox {
    margin-right: 16px;
}

.ui.checkbox label,
.ui.checkbox + label,
.ui.checkbox input:focus ~ label,
.ui.checkbox input:checked ~ .box::after,
.ui.checkbox input:checked ~ label::after {
    color: #4c4c4c;
}

.ui.form .disabled.field {
    opacity: 1;
}


.ui.form .field.disabled>label, .ui.form .fields.disabled>label {
    opacity: 1 !important;
}

@media (max-width: 767px) {
    .ui.form .fields {
        margin-bottom: 0;
    }

    .ui.form .fields .field {
        margin-bottom: 12px !important;
    }

}


/*  TABLE
-------------------------------------------------------------------------- */
.app-table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.ui.table {
	font-size: 14px;
	color: #4c4c4c;
	border: none;
}

.ui.table thead th {
	background: none;
	color: #000000;
	font-size: 13px;
	font-weight: 600;
	padding-top: 0;
    line-height: 1.1;
    vertical-align: bottom;
    white-space: nowrap;
}

.ui.table tbody tr {
	border-bottom: 1px solid #f5f5f5;
}

.ui.table tbody tr:hover {
	background-color: #fafafb;
}

.ui.table tbody tr td {
	border-top: 1px solid #f5f5f5;
	padding: 19px 11px;
}

.ui.table tbody tr td.td-status {
    white-space: nowrap;
}

.td-customer-name {
	display: flex;
	align-items: center;
}

.td-short-name {
	border: 1px solid #fdc027;
	border-radius: 50%;
	color: #fdc027;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	height: 20px;
	width: 20px;
	margin-right: 6px;
}

.td-full-name {
    flex: 1;
}

.ui.label {
    font-size: 14px;
	font-weight: normal;
	border-radius: 4px;
	margin: 0 .25em;
	display: inline-flex;
}

.td-action .ui.label {
    font-size: 14px;
    border-radius: 4px;
    padding: 10px 16px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.ui.green.label, .ui.green.labels .label {
	background-color: #4caf50 !important;
	border-color: #4caf50 !important;
	color: #ffffff !important;
}

.ui.blue.label, .ui.blue.labels .label {
	background-color: #2196f3 !important;
	border-color: #2196f3 !important;
	color: #ffffff !important;
}

.ui.orange.label, .ui.orange.labels .label {
	background-color: #ff9800 !important;
	border-color: #ff9800 !important;
	color: #fff !important;
}

.ui.red.label, .ui.red.labels .label {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
	color: #ffffff !important;
}

.td-status .ui.label {
    text-transform: capitalize;
}

.td-action .ui.label {
    cursor: pointer;
}

.td-status .ui.label img {
    max-width: 12px;
    margin-right: 6px;
    height: auto !important;
}

.ui.dropdown .menu .active.item {
    font-weight: 400;
}

/*  CARD STYLE
-------------------------------------------------------------------------- */
.app-card {
	background-color: #ffffff;
	border: 1px solid #f5f5f5;
	border-radius: 10px;
	padding: 30px 20px;
    margin-bottom: 20px;
}

.app-card-header {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app-card-header .sortby_options {
    min-width: auto;
}

.app-card-title {
    color:#58595B;
	font-size: 14px;
	font-weight: 700;
}

.app-card-subtitle {
    color: #777;
	font-size: 13px;
	font-weight: 700;
    margin-top: 2px;
}

/*  MODAL
-------------------------------------------------------------------------- */
.modal-header {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #e5e6e9;
    height: 56px;
}

.modal-header-title {
    font-size: 18px;
    font-weight: 600;
    flex: 1;
    padding-right: 45px;
}

.modal-header-close {
    opacity: .75;
    cursor: pointer;
}
  
.modal-header-close:hover {
    opacity: 1;
}

.modal-header-close i.icon {
    font-size: 16px;
    margin: 0;
}

.modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 168px);
    padding: 16px;
}

.modal-footer {
    height: 56px;
    border-top: 1px dashed #e5e6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
}

.modal-footer .ui.button {
    padding: 9px 15px;
}


/*  ADMIN DASHBOARD
-------------------------------------------------------------------------- */
.dashboard-statistics {
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.stats-col {
	padding-left: 10px;
	padding-right: 10px;
	flex: 0 0 auto;
	width: 33.333333%;
}

.customer-statistics .stats-col {
    width: 50%;
}

.dashboard-card-row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.latest-customers-container,
.latest-order-request-contaienr {
	padding-left: 10px;
	padding-right: 10px;
}

.latest-customers-container {
	flex: 0 0 auto;
	width: 25%;
}

.latest-order-request-contaienr {
	flex: 0 0 auto;
	width: 75%;
}

.app-latest-customer {
	display: flex;
}

.app-latest-customer + .app-latest-customer {
	margin-top: 38px;
}

.latest-customer-short-title {
	background-color: #00c4ff;
	border-radius: 50%;
	color: #ffffff;
	font-size: 12px;
	height: 40px;
	width: 40px;
	display: flex;
	letter-spacing: 1px;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	margin-right: 10px;
	float: left;
}

.latest-customer-detail {
	flex: 1;
	min-width: 0;
}

.latest-customer-title {
	font-size: 14px;
	font-weight: 400;
}

.latest-customer-email {
	color: #B7B2B2;
	font-size: 13px;
}

@media (max-width: 1200px) {
    .latest-order-request-contaienr {
        width: 100%;
        order: -1;
    }

    .latest-customers-container {
        width: 50%;
        margin-top: 20px;
    }
}

@media (max-width: 767px) {

    .stats-col,
    .customer-statistics .stats-col {
        width: 100%;
    }

    .stats-col + .stats-col {
        margin-top: 10px;
    }

    .latest-customers-container {
        width: 100%;
    }
}

/*  DASHBOARD CUSTOMER
-------------------------------------------------------------------------- */

.app-content-search {
    flex: 1;
    max-width: 420px;
    margin-right: 20px;
}
.app-header .app-content-search {
    width: 270px !important;
}
.app-content-search .ui.input {
    display: block;
}

.app-content-search .ui.input input {
    width: 100%;
    background-color: #f5f5f5;
    border: 1px solid #e6e7e9;
    border-radius: 7px;
    height: 41px;
}

.app-card.buildings-table {
    margin-top: 30px;
}

@media (max-width: 600px) {

    .app-content-action.app-content-action-search {
        display: block;
    }
    .app-content-action.app-content-action-search .app-content-search {
        margin-right: 0;
        margin-bottom: 10px;
        max-width: 100%;
    }
    .app-content-action.app-content-action-search .app-content-action-right .ui.button{
        width: 100%;

    }
}

.heatmap-container{
    border-width: 1px;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 520px) {
    .app-building-container .app-content-action-right{
        margin-left: 0;
        width: 100%;
    }
    .app-building-container .app-content-action-right .app-csv-link,
    .app-building-container .app-content-action-right .ui.button {
        width: 100%;
        margin: 0;
        text-align: center;
        margin-top: 6px;
    }
}


/*  CUSTOMER - ORDER REVIEW PAGE
-------------------------------------------------------------------------- */
/* .order-review-contaienr {} */

.order-review-header {
    border-bottom: 1px solid #D6D9D3;
    padding-bottom: 15px;
}

.followup-requests-header .app-content-search {
    margin-left: auto;
    margin-right: 0;
}

.followup-requests-header{
    display: flex;
    align-items: center;
}

.building-managment-header-card{
    display: flex;
    align-items: center;
}

.building-managment-header-card .app-content-search {
    margin-left: auto;
    margin-right: 0;
}

.order-review-title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px;
}

.order-review-subtitle {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 0;
}

.order-history-item {
    display: grid;
    flex-wrap: wrap;
    padding: 2px;
}

.order-review-subtitle > span:first-child {
    color: #000000;
    width: 140px;
}

.order-review-unit {
    margin-top: 15px;
}

.unit-detail-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.unit-detail-col {
    flex: 0 0 auto;
    width: 33.333333%;
    padding-left: 5px;
    padding-right: 5px;
}

.unit-detail-row .unit-detail-col:nth-child(n + 4) {
    margin-top: 30px;
}

.unit-detail-label {
    font-size: 12px;
    font-weight: 600;
    color: #B7B2B2;
}


.page-form-submit {
    text-align: center;
    margin-top: 60px;
}

.page-goback {
    margin-top: 20px;
}

.page-goback-link {
    text-decoration: underline;
    cursor: pointer;
}

.page-goback-message {
    margin-top: 2px;
}

@media (max-width: 767px) {

    .unit-detail-col {
        width: 50%;
    }

    .unit-detail-row .unit-detail-col:nth-child(n + 3) {
        margin-top: 30px;
    }
    
}

@media (max-width: 560px) {

    .unit-detail-col {
        width: 100%;
    }

    .unit-detail-row .unit-detail-col {
        margin-top: 30px;
    }

    .unit-detail-row .unit-detail-col:first-child {
        margin-top: 0;
    }

}


/*  ORDER DEAIL PAGE - CUSTOMER
-------------------------------------------------------------------------- */
.order-detail-top .app-card {
    background-color: transparent;
    padding: 30px 20px;
    border: none;
}

.order-summary .order-summary-title {
    float: left;
    min-width: 125px;
}

.order-summary-row {
    padding: 10px 30px;
}



/*  BUILDING MANAGEMENT
-------------------------------------------------------------------------- */
.building-update-submit {
    text-align: right;
}

.add-building-submit {
    text-align: right;
    margin-top: 20px;
}

.building-add-unit-container {
    margin-top: 20px;
}

.buildding-add-unit-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.building-add-unit-title {
    font-weight: 700;
    font-size: 14px;
}

.building-added-unit-list {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-gap: 20px;
}

.added-unit-card {
    background-color: #fff;
    border: 1px solid #EBF1FE;
    border-radius: 5px;
    position: relative;
}

.added-unit-card-header {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 0 5px;
}

.added-unit-card-header .ui.buttons {
    font-size: 16px;
}

.added-unit-card-header .ui.dropdown .menu {
    min-width: 120px;
}

.added-unit-card-header .warranty-badge {
    position: relative;
    display: none;
}
.add-unit-menu{
    margin-left: auto !important;
}
.warranty-card .added-unit-card-header .warranty-badge {
    display: block;
}

.added-unit-card-header .warranty-badge .warranty-text {
    background-color: #ff9800;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff;
    padding: 3px 10px;
    height: 20px;
    letter-spacing: 0.75px;
}

.warranty-valid .added-unit-card-header .warranty-badge {
    display: block;
}

.added-unit-card-header.warranty-expired .warranty-badge .warranty-text {
    background-color: #db2828;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff;
    padding: 3px 10px;
    height: 20px;
    letter-spacing: 0.75px;
}

div#order_type_request {
    /* min-width: 80px !important; */
}

.search-orders-list .ui.input {
    min-width: 400px;
    border-radius: 9px !important;
}

.search-orders-list input.prompt {
    border-radius: 9px !important;
}

.added-unit-card-header .warranty-badge .warranty-text::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 100%;
    border-top: 10px solid transparent;
    border-left: 11px solid #ff9800;
    border-bottom: 10px solid transparent;
}

.added-unit-card-header.warranty-expired .warranty-badge .warranty-text::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 100%;
    border-top: 10px solid transparent;
    border-left: 11px solid #db2828;
    border-bottom: 10px solid transparent;
}

.added-unit-card-header .ui.icon.button {
    padding: 0 5px;
    background-color: transparent;
    height: 100%;
}

.added-unit-card-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 15px;
}

.added-unit-card-item + .added-unit-card-item {
    border-top: 1px solid #EBF1FE;
}


/*  LOGIN PAGES
-------------------------------------------------------------------------- */
.login-wrapper {
    background-image: url(login.jpg);
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.login-card {
    background-color: #ffffff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    padding: 60px
}

.login-card-header {
    text-align: center;
    margin-bottom: 40px;
}

.login-form .button-form-submit {
    width: 100%;
    display: block;
    margin-top: 30px;
}

.loing-card-footer {
    margin-top: 45px;
}

.loing-card-footer-text {
    text-align: center;
}

.loing-card-footer-text span {
    color: #0043FF;
}

@media (max-width: 530px) {
    .login-card {
        padding: 45px 30px;
    }
}

@media (max-width: 360px) {
    .login-card {
        padding: 30px 20px;
    }
}


/*  STYLING FOR IE ONLY
-------------------------------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .app-content-action .app-content-action-right {
        flex: none;
    }
    .app-content-action-right {
        flex: 1;
    }
    .app-content-action-right .ui.red.button, .app-content-action-right .ui.orange.button  {
        min-width: 175px;
    }
    .ui.red.button, .ui.red.button:focus, .ui.red.buttons .button:focus {
        float: right;
    }
    .page-form-submit-button, .modal-goback-button {
        display: inline-flex;
    }
    .pagination-group .ui.form {
        min-width: 50px;
    }
    .ui.form.login-form {
        display: inline-block;
        width: 100%;
    }
    .app-card-title {
        flex: 1;
    }
    .building-added-unit-list .added-unit-card {
        display: inline-block;
        min-width: calc(20% - 7px);
        margin-right: 7px;
    }
    .app-content-action.app-content-action-search .app-content-action-right {
        flex: 1;
    }
    .login-card {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .app-content-action.app-content-action-search .app-content-search {
        display: inline-table;
    }
}

div.ui.selection.dropdown.single-line-select div.divider.default.text{
    overflow-x: clip;
    overflow-y:visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}
.sidebar div.divider.text{
    overflow-x: clip;
    overflow-y:visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}
.alert-message {
    margin-bottom: 10px;
}
.ui.inverted.red.button, .ui.inverted.red.buttons .button{
    box-shadow:none!important;
}
.account-dropdown .menu.transition.visible .item{
    padding-left: 0!important;
    }
.ui.purple.tiny.progress {
    background-color: #a333c829;
    }