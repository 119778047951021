#mdl-confirmation {
    max-width: 400px;
    width: 100%;
}

#mdl-confirmation .modal-content {
    padding-top: 32px;
    padding-bottom: 32px;
}

.confirmation_message {
    font-size: 16px;
    color: #b7b2b2;
    text-align: center;
}

@media (max-width: 430px) {
    #mdl-confirmation {
        max-width: calc(100% - 30px);
    }
}