
.single-line-select{
    height: 12px;max-width:'90%';text-overFlow: ellipsis;max-width: 90%;
}

.column.po_number .ui.input {
    width: 100%;
}
.column.po_number label {
    /* width: 100%; */
    display: flex;
}