.chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .chip {
    border-radius: 16px;
    padding: 8px 12px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #ddd;
    color: #333; /* Text color */
  }
  
  .chip-content {
    margin-bottom: 4px;
  }
  
  .chip-label {
    font-weight: bold;
    margin-right: 4px;
  }
  
  .chip-value {
    color: #333;
  }

  .app-table-wrapper button.ui.green.button {
    margin-top: 18px;
}