.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.not-found-holder {
    text-align: center;
    max-width: 520px;
}

.not-found-404 {
    color: #B7B2B2;
    font-size: 160px;
    font-weight: 700;
    line-height: 1;
}

.not-found-message {
    font-size: 16px;
    line-height: 1.2;
    margin-top: 12px;
    font-weight: 700;
    margin-top: 40px;
    
    text-transform: uppercase;
}

.not-found-back-button {
    margin-top: 25px;
}