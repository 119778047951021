.dashboard-stats {
    position: relative;
    padding-bottom: 10px;
    cursor: pointer;
}

.dashboard-stats-holder {
    display: flex;
    flex-wrap: wrap;
}

.stats-chart{
    width: 80px;
    margin-left: 20px;
}

.stats-content {
    flex: 1;
    display: flex;
    align-items: center;
}

.stats-icon {
    margin-right: 20px;
}

.stats-detail {
    flex: 1;
}

.stats-title {
    color: #000000;
    font-size: 16px;
    font-weight: 600;
}

.stats-count {
    font-size: 24px;
    color: #B7B2B2;
    margin-top: 6px;
}

.stats-progress {
    height: 80px;
    width: 80px;
}

.progress-orange .CircularProgressbar .CircularProgressbar-path {
    stroke : #FA8C16;
}

.progress-blue .CircularProgressbar .CircularProgressbar-path {
    stroke : #05881F;
}

.progress-green .CircularProgressbar .CircularProgressbar-path {
    stroke : #05881F;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #B7B2B2;
    font-size: 18px;
}

.dashboard-stats-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    line-height: 1;
}

.progress-help-text,
.last-month-count {
    font-size: 11px;
    font-weight: 600;
}
.last-month-count {
    margin-right: 5px;
}

@media (max-width: 1200px) {

    .app-main-admin .stats-progress {
        height: 55px;
        width: 55px;
    }

    .app-main-admin .stats-icon {
        width: 36px;
        margin-right: 12px;
    }

    .app-main-admin .stats-title {
        font-size: 14px;
    }
    
    .app-main-admin .stats-count {
        font-size: 20px;
        margin-top: 3px;
    }

}

@media (max-width: 991px) {

    .dashboard-statistics .app-card {
        padding: 20px 15px;
    }

    .stats-progress {
        height: 60px;
        width: 60px;
        margin-left: 5px;
    }

    .stats-icon {
        width: 40px;
        margin-right: 12px;
    }

    .stats-title {
        font-size: 14px;
    }
    
    .stats-count {
        font-size: 20px;
        margin-top: 3px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .app-main-admin .stats-icon {
        width: 30px;
    }

    .app-main-admin .stats-progress {
        height: 50px;
        width: 50px;
    }

    .progress-help-text, .last-month-count {
        font-size: 9px;
    }
}

