.column.expand-header {
    font-weight: bold;
    color: black;
    font-size: smaller;
}
.ui.padded.segment {
    margin-inline: 25px;
}

button.ui.mini.button.btn-uncheck {
    margin-right: 10px;
}