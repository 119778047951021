.view-customer-section {
    border-top: 1px solid #f5f5f5;
}

.view-customer-row {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;
    margin-top: 10px;
}

.view-customer-label {
    width: 205px;
    color: #000000;
}

.view-customer-field {
    flex: 1;
    text-align: center;
}

.view-customer-manage-label {
    width: 105px;
    color: #000000;
}

.view-customer-manage-field {
    flex: 1;
}

.app-card-settings-update {
    padding-inline: 30px;
    padding-top: 30px;
    padding-bottom: 0px;
}

.settings-bold {
    font-weight: bold;
}

@media (max-width: 560px) {
    .view-customer-row + .view-customer-row {
        margin-top: 4px;
    }
    
    .view-customer-label,
    .view-customer-field {
        width: 100%;
    }
}