#mdl-pdf-viewer {
    height: calc(100% - 56px);
}

#mdl-pdf-viewer .modal-content {
    padding: 0;
    max-height: calc(100% - 56px);
    height: 100%;
    overflow: hidden;
}
.ifram-pdf {
height: 100%;
width: 100%;
border: none;
position: relative;
z-index:1001;
opacity: 0;
}

.confirmation_message {
    font-size: 16px;
    color: #b7b2b2;
    text-align: center;
}

@media (max-width: 430px) {
    #mdl-confirmation {
        max-width: calc(100% - 30px);
    }
}