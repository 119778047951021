#mdl-upload-bulk-result {
    max-width: 620px;
    width: 100%;
}

@media (max-width: 650px) {
    #mdl-upload-bulk-result {
        max-width: calc(100% - 30px);
    }
}

.bulk-upload-result-card ul {
    list-style: none;
    padding: 0 0 20px;
    margin: 0;
}

.bulk-upload-result-card ul li {
    padding: 5px 0;
    display: flex;
    color: #000;
    font-size: 13px;
}

.bulk-upload-result-card ul li .result-card-label {
    font-size: 13px;
    margin-right: 3px;
}

.bulk-upload-result-card ul li .result-card-result {
    font-weight: 600;
}

.bulk-upload-result-card ul li.result-uploaded {
    color: #4caf50;
}

.bulk-upload-result-card ul li.result-error {
    color: #f44336;
}

.bulk-upload-result-card ul li.result-uploaded .result-card-result {
    color: #4caf50;
}

.bulk-upload-result-card ul li.result-error .result-card-result {
    color: #f44336;
}