.heatmap-container{
    margin: 24px auto;
    /* height: 75vh; */
    /* width:60%;
    margin:0 auto; */
}
div.order-history-item span{

    font-size:12px;

}
/* ------------------------------------------------------------------------------- */
    /* Styles for Building History */
    .building-history-title-container{
        display:flex;
        justify-content:flex-start;
        margin-bottom:30px;
        padding: 8px 0;
    }
    .empty-table{
        text-align: center;
    }
    .border-bottom{
        border-bottom: 2px solid grey;
    }
    .border-top{
        border-top: 2px solid grey;
    }
    .comment-spacing{
        padding:4px 1rem;
    }
    .row-p0{
        padding:0!important;
    }.building-name-title{
        font-weight:bold;
        padding-right:4px;
        font-size:14px;
    }.flex{
        display:flex;
    }
    .flex-column{
        flex-direction:column;
    }
    .text-center{
        text-align:center;
    }
    .bg-red{
        background-color:red;
    }
    .grid-border{
        border:1px solid #000000a3;
        border-bottom:none;
        border-right:none;
    }
    .borderTop{
        border-top: 1px solid#0000006e;
    }
    .column-border{
        border-right:1px solid #0000006e;
        padding: 4px;
        min-width: 90px;
        min-height: 90px;
        align-items: center;
        display: flex;
        border-bottom:1px solid#0000006e;
    }
    .column-border-report{
        border-right:1px solid #0000006e;
        /* padding: 4px; */
        min-width: 70px;
        max-width:80px;
        min-height: 50px;
        align-items: center;
        display: flex;
        border-bottom:1px solid#0000006e;
    }
    .empty-column-border{
        padding: 8px 4px;
        min-width: 80px;
    }
    .text-wrap{
        display:block;
        width:80px;
        word-wrap:break-word;
    }
    .heatmap-container .flex.flex-column.flex-center.icon-container-size {
        min-width: 80px;
    }
    
    .w-60{
        width:60%
    }
    .w-40{
        width:40%
    }
    .justify-content-right{
        justify-content:flex-end;
    }
    .legend-spacing{
        padding: 4px 8px;
        /* outline:1px solid #000000a3; */
    }
    .light-activity{
        background-color:#FFFF00 ;
    }
    .moderate-activity{
        background-color:#FFC000 ;
    }
    .heavy-activity{
        background-color:#FF0000 ;
    }
    .not-serviced {
        background-color: lightgrey;
        /* border-top: 1px solid #000000a3; */
    }
    
    
    .building-name{
        font-size:22px;
    }
    .comment-text{
        color: #0043FF;
        font-weight:bold;
    }
.app-card-header{
    padding:8px 0;
}
.icon-container-size{
    /* width:69px; */
    /* height: 40px; */
}
.flex-center{
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:991px){
    .legends-mobile{
        flex-direction:column;
        width: fit-content
    }
    .legends-mobile-row{
        flex-direction:row;
    }
    .w-50{
        width:50%;
    }
}

.label-text{
    font-weight:bold;
}
.table-border{
    outline:1px solid black
}

.table-data{
    border-right:1px solid black;
    padding: 4px 8px;
}
.mobile-table-row{
    display: block;
    float:right;
    border-collapse: collapse;
}
@media only screen and (max-width:664px){
    .mobile-table{
        display: flex;
        flex-direction: column;
    }
    .mobile-table-row{
        display: flex;
        float:none;
    }
    .table-data:not(:last-of-type){
        border-bottom:1px solid black;
    }

} 
.first-column-spacing{
    /* padding:8px 16px 8px 32px; */
    font-weight: 600;
    padding: 18px 8px;
    color: #000000;
    border-right: 1px solid #0000006e;
    width: 130px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
}
.heatmap-container{
    overflow: overlay;
}
.text-bold{
    font-weight: 700;
}

.empty-column-border:has(+ :not(.empty-column-border)) {
    border-right:1px solid#0000006e;
}

.row-wrapper{
    align-items: stretch!important;
}