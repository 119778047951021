#mdl-add-unit {
    max-width: 440px;
    width: 100%;
}
#mdl-add-unit .ui.selection.dropdown .menu{
    max-height: 17.371429rem;
}
@media (max-width: 480px) {
    #mdl-add-unit {
        max-width: calc(100% - 30px);
    }
}