#mdl-critical-detail .modal-content.fields {
    overflow-y: scroll;
    overflow-x: hidden;
}

.critical-filter-container {
    display: flex;
}

div#scheduler_type {
    margin-right: 10px;
    min-width: 232px;
}

.critical-filter-item.export-icon img {
    max-width: 125px;
    max-height: 20px;
    margin: 0 !important;
}

.critical-filter-item.refresh-icon img {
    max-width: 60px !important;
    max-height: 19px !important;
    margin: 0 !important;
}

.error input#tech_reported {
    background: #fff6f6;
    border-color: red;
}