.finish-request-actions {
    text-align: right;
    margin-top: 15px;
    display:flex;
    justify-content:flex-end
}

@media only screen and (max-width:512px) {
    .finish-request-actions{
    flex-direction:column;
    gap: 8px;
}
.finish-request-actions button.ui.grey.button.add-unit-button {
    margin-left: 8px;
}
}

.finish-request-actions .ui.button {
    vertical-align: middle;
}

.finish-request-actions .ui.button.add-unit-button {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.order-card-row{
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.order-details-container {
	flex: 0 0 auto;
	width: 75%;
}

.order-summary-container {
	flex: 0 0 auto;
	width: 25%;
}

.order-app-card{
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    padding: 30px 20px 10px;
    margin-bottom: 20px;
}

.order-details-container,
.order-summary-container {
	padding-left: 10px;
	padding-right: 10px;
}

.order-list-item {
    display: flex;
}

span.order-title {
    width: 100%;
}

.order-list-header {
    display: flex;
    margin-bottom: 10px;
}

.list-header-one {
    width: 100%;
}

.order-list-header {
    font-weight: bold;
}