#mdl-update-unit {
    max-width: 440px;
    width: 100%;
}
#mdl-update-unit .ui.selection.dropdown .menu{
    max-height: 17.371429rem;
}
#mdl-update-building {
    max-width: 640px;
    width: 100%;
}
#mdl-update-building .ui.selection.dropdown .menu{
    max-height: 17.371429rem;
}
@media (max-width: 480px) {
    #mdl-update-unit {
        max-width: calc(100% - 30px);
    }
    #mdl-update-building {
        max-width: calc(100% - 30px);
    }
}