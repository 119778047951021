.add-unit-container .app-card {
    margin-top: 20px;
    position: relative;
}

.icon-remove-unit {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    line-height: 1;
    opacity: 0.75;
    font-size: 16px;
}

.icon-remove-unit:hover {
    opacity: 1;
}

@media (max-width: 767px) {

    .ui.form .fields.addunit-textarea-field > .field {
        width: 100%;
        flex: 0 1 auto;
    }

}

