.finish-request-actions {
    text-align: right;
    margin-top: 15px;
    display:flex;
    justify-content:flex-end
}

@media only screen and (max-width:512px) {
    .finish-request-actions{
    flex-direction:column;
    gap: 8px;
}
.finish-request-actions button.ui.grey.button.add-unit-button {
    margin-left: 8px;
}
}

.finish-request-actions .ui.button {
    vertical-align: middle;
}

.finish-request-actions .ui.button.add-unit-button {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}