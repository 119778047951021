#mdl-forgot-pin-customer {
    max-width: 420px;
    width: 100%;
}

text.forgot-pin-link {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 50px;
}

@media (max-width: 450px) {
    #mdl-forgot-pin-customer {
        max-width: calc(100% - 30px);
    }
}