.popup-notification {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    z-index: 1000;
  }
  
  .popup-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  button {
    background-color: #ff4d4f;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }