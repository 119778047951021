
.app-content-action-right .radio-toggle {
    margin-right: 10px !important;
}

.selection-mode{
text-align: center;
}

.column-border {
    position: relative;
   
  }
  
  .icon-add {
    display: none;
    /* visibility: hidden; */
  }
  a:hover{
    text-decoration: underline!important;
  }
  
  a[tabindex]:focus {
    color:#681DA8
  }
  
  .column-border:hover .icon-add {
    /* visibility: visible; */
    display: block;
  }
  .text-underline{
    text-decoration: underline;
  }

  /* .secondary-border{
   
    background: linear-gradient(to top, #1678C2 3px, transparent 1px),linear-gradient(to bottom, #1678C2 3px, transparent 1px), linear-gradient(to left, #1678C2 3px, transparent 1px), linear-gradient(to right, #1678C2 3px, transparent 1px);
  } */

  /* .secondary-border .flex.flex-column.flex-center.icon-container-size {
    border: 4px solid #2185d0 !important;
    width: 80px;
    height: 80px;
    border-radius: 6px;
} */

.secondary-border span.icon-add {
  display: block !important;
}

.note {
  position: absolute;
  top: 0;
  right: 20px;
}
.note:after { 
  content: "";
  position: absolute;
  /* top: 0;
  right: 0; */
  width: 0; 
  height: 0; 
  display: block;
  border-left: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid #00800061;
}

i.bordered.icon{
  margin:0!important;
  border: none!important;
  box-shadow: none!important;

}
.ui.selection.dropdown[name="updated_select"] div.divider.text{
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  /* overflow-x: hidden; */
  height:16px;
}
.ui.selection.dropdown[name="updated_select"]{
  border:none!important;
  min-width: 10em!important;
  background:#E0E1E2!important;
  
  /* border-left:1px solid grey!important; */
}
.ui.selection.dropdown.search[name="building"]{
  border:1px solid #e5e6e9!important;
  min-width: 14em!important;
  background:#fff!important;
  /* border-left:1px solid grey!important; */
}
.stats{
  display:flex;
  flex-direction:column;
  width: 20%;
}
.field.sortby_options{
  /* border:1px solid grey; */
  border-radius:4px;
  background:#D5D6D7;
}
.sortby_options{
  padding:0!important;
}
i.icon{
  
}.app-card-title{
 color:black;
}

.ui.tabular.menu .active.item {
background: #FAFAFB!important;
 
border-bottom: 3px solid #19C471!important;
}

.ui.tabular.menu .item {
  background: #FAFAFB!important;
}

.ui.tabular.menu .item {
  /* background: 0 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-top: 2px solid rgba(0, 0, 0, 0);
  border-bottom: none; */
  padding: 0.928571em 4.428571em;
  /* color: rgba(0, 0, 0, .87); */
}

i.icon.table{
  font-size:1.5em!important;
}
i.icon.list.alternate.outline{
  font-size:1.5em!important;
}
a.item:hover{
  text-decoration: none!important;
}

.ui.inverted.progress{
  /* border:1px solid!important; */
  
}

.ui.progress{
  /* border:1px solid!important; */
  
}
.ui.inverted.progress>.label {
  color:black!important;
}
.lightactivity .bar{
  background:#cfcf00!important;
}
.ui.progress.lightactivity{
  background:#ffff002b!important;
}
.moderateactivity .bar{
  background:#FFC000!important;
}
.ui.progress.moderateactivity{
  background:#ffbf001f!important;
}
.heavyactivity .bar{
  background:#FF0000!important;
}
.ui.progress.heavyactivity {
  background:#ff000024!important;
}
.notserviced .bar{
  background:#b0b0b0!important;
}
.notprepared .bar{
  background:#f2711c!important;
}
.ui.progress.notprepared{
  background:#f2721c2b!important;
}
.ui.progress.structural{
  background:#00b5ac2e!important;
}
.noneactivity .bar{
  background:rgba(0, 128, 0, .3803921568627451)!important;
}
.ui.progress.noneactivity{
  background:rgb(0 128 0 / 18%)!important;
}
.none-activity{
  background-color:#fff!important;
}


element.style {
  width: 0%;
}
.ui.tiny.progress .bar {
  height: .5em;
}
.ui.purple.progress .bar {
  background-color: #a333c8;
}
.ui.progress .bar {
  height: 1.75em;
}
.ui.progress .bar {min-width:0!important;}
.required.three.wide.field.sortby_options{
  display:flex;
  align-items: center;
}.three.wide.field.sortby_options{
  display:flex;
  align-items: center;
}

.filter.bordered.icon{
  min-width:28px!important;min-height:28px!important;
}

.ui.tabular.menu .item {
  /* background: 0 0;
  border: 1px solid rgba(0, 0, 0, 0);
  border-top: 2px solid rgba(0, 0, 0, 0);
  border-bottom: none; */
  padding: 0.928571em 4.428571em!important;
  /* color: rgba(0, 0, 0, .87); */
}
