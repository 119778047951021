
.document-item{

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        cursor: pointer;

}

