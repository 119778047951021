#mdl-warranty_detail {
    max-width: 420px;
    width: 100%;
}
#mdl-warranty_detail .modal-content .fields{
    display: flex;
    flex-direction: column;
    gap:8px;
    overflow: visible;
}


@media (max-width: 450px) {
    #mdl-warranty_detail {
        max-width: calc(100% - 30px);
    }
}
.ui.icon.input{
    width:100%;
}