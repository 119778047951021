.view-scheduler-section {
    border-top: 1px solid #f5f5f5;
    padding: 20px 0;
}

.view-scheduler-row {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;
}

.view-scheduler-label {
    width: 205px;
    color: #000000;
}

.view-scheduler-field {
    flex: 1;
}

@media (max-width: 560px) {
    .view-scheduler-row + .view-scheduler-row {
        margin-top: 4px;
    }

    .view-scheduler-label,
    .view-scheduler-field {
        width: 100%;
    }
}