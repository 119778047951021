.app-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.app-copyright {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.app-main.app-main-admin + .app-footer .app-copyright{
    padding-left: 250px;
}

@media (max-width: 1024px) {
    .app-main.app-main-admin + .app-footer .app-copyright {
        padding-left: 30px;
    }
}

@media (max-width: 767px) {
    .app-main.app-main-admin + .app-footer .app-copyright {
        padding-left: 0;
    }
}