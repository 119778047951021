#mdl-warranty {
    max-width: 920px;
    width: 100%;
}
#mdl-warranty .modal-content {
    overflow-x: scroll;
}

button.ui.red.button.modal-warranty-detail-button {
    padding: 10px;
    margin-bottom: 10px;
}

.modal-warranty-info {
    margin: 20px 0;
}

@media (max-width: 450px) {
    #mdl-warranty {
        max-width: calc(100% - 30px);
    }
}