#mdl-success {
    max-width: 400px;
    width: 100%;
}

#mdl-success .modal-content {
    padding-top: 32px;
    padding-bottom: 32px;
    text-align: center;
}

.modal-success-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
}

.modal-success-title img {
    max-width: 36px;
    margin-right: 12px;
}

.modal-success-message {
    font-size: 20px;
    margin-top: 30px;
}

.modal-goback-button {
    margin-top: 30px;
}

@media (max-width: 430px) {
    #mdl-success {
        max-width: calc(100% - 30px);
    }
}