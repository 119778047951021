.order-review-container-bg .order-review-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.order-review-container-bg .order-review-header {
    border-bottom: 1px solid #d6d9d3;
    padding-bottom: 15px;
}

.order-review-left {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
}

.order-review-left, .order-review-right {
    padding-left: 10px;
    padding-right: 10px;
}

.order-review-right {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
}