.view-customer-section {
    border-top: 1px solid #f5f5f5;
    padding: 20px 0;
}

.view-customer-row {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0;
}

.view-customer-label {
    width: 205px;
    color: #000000;
}

.view-customer-field {
    flex: 1;
}

@media (max-width: 560px) {
    .view-customer-row + .view-customer-row {
        margin-top: 4px;
    }
    
    .view-customer-label,
    .view-customer-field {
        width: 100%;
    }
}